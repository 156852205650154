<template>
  <!-- <OrderInformation /> -->
  <!-- <AddressInformation /> -->
  <component :is="component" ref="paymentMethod" />
  <ItemsToShip ref="itemsToShip" />
  <InformationPageHeader>
    <sdButton
      v-if="isCreate && !!storeview.shipment_with_label"
      class="mt-20"
      type="warning"
      @click="createShipmentWithLabel"
    >
      Create Fulfilment & Label
    </sdButton>
    <sdButton
      v-if="isCreate && !!storeview.shipment_without_label"
      class="mt-20"
      type="warning"
      @click="createShipmentWithoutLabel"
    >
      Create Fulfilment Without Label
    </sdButton>
    <sdButton
      v-if="isCreate && !!storeview.shipment_without_tracking"
      class="mt-20"
      type="warning"
      @click="createShipment(false)"
    >
      Create Fulfillment Only
    </sdButton>
    <sbButton v-if="!isCreate" class="mt-20" type="warning" @click="$router.push({ name: 'omni-order-shipments' })">
      Fulfilment List
    </sbButton>
  </InformationPageHeader>

  <a-modal
    type="primary"
    title="Create Label & shipment"
    :visible="withLabelModalVisible"
    width="70%"
    :onCancel="() => (withLabelModalVisible = false)"
  >
    <CreateLabelShipmentWrapper>
      <sdPageHeader title="Parcel">
        <template v-slot:buttons>
          <sdButton
            v-if="labelUrl"
            size="small"
            :outlined="true"
            type="warning"
            :disabled="savingShipment || createLabelLoading"
            @click="viewLabel"
          >
            View Label
          </sdButton>
          <sdButton v-else :outlined="true" size="small" type="primary" @click="createLabel"> Create Label </sdButton>
        </template>
      </sdPageHeader>
      <a-form name="parcelBody" :model="parcelForm" ref="formRef" :rules="rules">
        <a-form-item name="width" label="Width" ref="width">
          <a-input v-model:value="parcelForm.width" type="number" min="0" />
        </a-form-item>
        <a-form-item name="height" label="Height" ref="height">
          <a-input v-model:value="parcelForm.height" type="number" min="0" />
        </a-form-item>
        <a-form-item name="length" label="Length" ref="length">
          <a-input v-model:value="parcelForm.length" type="number" min="0" />
        </a-form-item>
      </a-form>
      <TableWrapper>
        <a-table
          :dataSource="withLabelTableData"
          :columns="withLabelTableColumns"
          :pagination="{ defaultPageSize: 5 }"
        />
      </TableWrapper>
    </CreateLabelShipmentWrapper>
    <template #footer>
      <a-button key="back" @click="() => (withLabelModalVisible = false)" :disabled="savingShipment"> Return </a-button>
      <a-button key="submit" type="primary" @click="createShipment(true)" :disabled="!labelUrl || savingShipment">
        Create Shipment
      </a-button>
    </template>
  </a-modal>

  <a-modal
    type="primary"
    :visible="withoutLabelModalVisible"
    width="70%"
    :onCancel="() => (withoutLabelModalVisible = false)"
  >
    <template #title>
      <div class="ant-modal-title">Create tracks</div>
      <div class="ant-modal-description">This step is able to be skipped.</div>
    </template>
    <CreateShipmentWrapper>
      <TableWrapper>
        <a-table
          :dataSource="withoutLabelTableData"
          :columns="withoutLabelTableColumns"
          :loading="savingShipment"
          :pagination="{ defaultPageSize: 5 }"
        >
          <template #title>
            <sdButton type="primary" @click="addTrack" :disabled="savingShipment">Add Track</sdButton>
          </template>
        </a-table>
      </TableWrapper>
    </CreateShipmentWrapper>

    <template #footer>
      <a-button key="back" @click="() => (withoutLabelModalVisible = false)" :disabled="savingShipment">
        Return
      </a-button>
      <a-button key="submit" type="primary" @click="createShipment(false)" :disabled="savingShipment">
        Create Shipment
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import OrderInformation from '../information/OrderInformation.vue';
import AddressInformation from '../information/AddressInformation.vue';
import PaymentMethod from './PaymentMethod.vue';
import ItemsToShip from './ItemsToShip.vue';
import ShipmentViewPaymentMethod from './ShipmentViewPaymentMethod.vue';
import { InformationPageHeader } from '../information/style';
import { CreateLabelShipmentWrapper, CreateShipmentWrapper } from './style';
import { TableWrapper, BasicFormWrapper } from '@/view/styled';
import { DataService } from '@/config/dataService/dataService';
import { Notification } from 'ant-design-vue';

const withLabelTableColumns = [
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
  },
  {
    title: 'Weight',
    dataIndex: 'weight',
    key: 'weight',
  },
  {
    title: 'Qty',
    dataIndex: 'qty',
    key: 'qty',
  },
  {
    title: 'Qty to ship',
    dataIndex: 'qty_to_ship',
    key: 'qty_to_ship',
  },
];

const withoutLabelTableColumns = [
  {
    title: 'Carrier',
    dataIndex: 'carrier_code',
    key: 'carrier_code',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Number',
    dataIndex: 'track_number',
    key: 'track_number',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];

export default {
  name: 'CreateOrderShipment',
  components: {
    OrderInformation,
    AddressInformation,
    PaymentMethod,
    ItemsToShip,
    InformationPageHeader,
    ShipmentViewPaymentMethod,
    CreateLabelShipmentWrapper,
    TableWrapper,
    BasicFormWrapper,
    CreateShipmentWrapper,
  },
  data() {
    return {
      component: this.isCreate ? 'PaymentMethod' : 'ShipmentViewPaymentMethod',
      withLabelModalVisible: false,
      withoutLabelModalVisible: false,
      parcelForm: {
        width: null,
        height: null,
        length: null,
      },
      itemsWeight: {},
      withLabelTableColumns,
      withoutLabelTableColumns,
      createLabelLoading: false,
      label: null,
      rules: {
        width: [
          {
            required: true,
            message: 'Please input width',
            trigger: 'blur',
          },
        ],
        height: [
          {
            required: true,
            message: 'Please input height',
            trigger: 'blur',
          },
        ],
        length: [
          {
            required: true,
            message: 'Please input length',
            trigger: 'blur',
          },
        ],
      },
      tracks: [],
      savingShipment: false,
    };
  },
  computed: {
    isCreate() {
      return this.$route.params.shipmentId === 'create';
    },
    items() {
      if (this.isCreate) {
        return this.$store.state.orders.order.items || [];
      } else {
        const currentShipmentItemsIds = this.$store.state.orders.shipment
          ? this.$store.state.orders.shipment.items.map((item) => item.entity_id)
          : [];

        return this.$store.state.orders.order.items
          ? this.$store.state.orders.order.items.filter((item) => currentShipmentItemsIds.includes(item.item_id))
          : [];
      }
    },
    labelUrl() {
      return this.label ? this.label.uri : null;
    },
    withLabelTableData() {
      const itemsQty = this.$refs['itemsToShip'].itemsQty;

      return this.items.length
        ? this.items
            .filter(({ item_id }) => itemsQty[item_id] && itemsQty[item_id] > 0)
            .map((item) => {
              const { item_id, name, sku, qty_ordered } = item;

              return {
                key: item_id,
                product: (
                  <div>
                    <div>
                      {name} - {sku}
                    </div>
                    <div>SKU: {sku}</div>
                  </div>
                ),
                weight: (
                  <div>
                    <a-input
                      value={this.itemsWeight[item_id]}
                      type="number"
                      min="0"
                      onChange={(e) => (this.itemsWeight[item_id] = e.target.value)}
                    />
                  </div>
                ),
                qty: `Ordered ${qty_ordered}`,
                qty_to_ship: <div class="table-last-input-column">{itemsQty[item_id] ? itemsQty[item_id] : 0}</div>,
              };
            })
        : [];
    },
    withoutLabelTableData() {
      return this.tracks.map((track, index) => {
        const { carrier_code, title, track_number } = track;
        const removeTrack = (trackIndex) => {
          this.tracks = this.tracks.filter((track, i) => trackIndex !== i);
        };

        return {
          key: index,
          carrier_code: (
            <div>
              <a-input value={carrier_code} onChange={(e) => (this.tracks[index]['carrier_code'] = e.target.value)} />
            </div>
          ),
          title: (
            <div>
              <a-input value={title} onChange={(e) => (this.tracks[index]['title'] = e.target.value)} />
            </div>
          ),
          track_number: (
            <div>
              <a-input value={track_number} onChange={(e) => (this.tracks[index]['track_number'] = e.target.value)} />
            </div>
          ),
          action: (
            <div class="table-actions">
              <a-popconfirm title="Sure to delete?" onConfirm={() => removeTrack(index)}>
                <sdButton class="btn-icon" type="default" shape="circle">
                  <sdFeatherIcons type="trash" size="16" />
                </sdButton>
              </a-popconfirm>
            </div>
          ),
        };
      });
    },
    storeview() {
      const activeStoreview = this.$store.state.storeviews.storeview;
      const userStoreviews = this.$store.state.auth.user.store_views;
      if (activeStoreview?.id !== -1) {
        return userStoreviews.find((userStoreview) => userStoreview?.id === activeStoreview.id);
      } else {
        return userStoreviews[0];
      }
    },
  },
  methods: {
    createShipmentWithoutLabel() {
      this.withoutLabelModalVisible = true;
      // await this.createShipment();
    },
    createShipmentWithoutModal() {
      // await this.createShipment();
    },
    async createShipmentWithLabel() {
      if (this.isCreate) {
        const itemsQty = this.$refs['itemsToShip'].itemsQty;

        this.withLabelModalVisible = true;
        this.parcelForm = {
          width: null,
          height: null,
          length: null,
        };
        this.items
          .filter(({ item_id }) => itemsQty[item_id] && itemsQty[item_id] > 0)
          .map(({ item_id }) => {
            this.itemsWeight[item_id] = 1;
          });
        this.label = null;
      } else {
        this.$router.push({ name: 'omni-order-shipments' });
      }
    },
    async createLabel() {
      const validate = await this.$refs['formRef'].validate();
      if (validate) {
        let payload = {
          shipment: {
            label_size: '6x4',
            label_format: 'pdf',
            generate_invoice: false,
            generate_packing_slip: false,
            collection_date: '2019-11-18T16:30:49.054Z',
            reference: 'my reference',
            delivery_instructions: 'Leave on the porch',
            smart_shipping_group: '',
            shipping_charge: 2.5,
            requested_shipping_service: 'FAST',
            ship_from: {
              name: 'MN',
              phone: '01377337164',
              email: 'foo@foo.com',
              company_name: 'Fake Company',
              address_1: 'unit 76',
              address_2: 'warfield road',
              address_3: '',
              city: 'Driffield',
              postcode: 'YO25 9DJ',
              county: '',
              country_iso: 'GB',
              company_id: '911-70-1234',
              tax_id: '911-70-1234',
              eori_id: '911-70-1234-000',
              ioss_number: 'IM2760000711',
            },
            ship_to: {
              name: 'Laura Maura',
              phone: '466546346546',
              email: 'support@buyer.com',
              company_name: '',
              address_1: 'Ekeredsvaegen 132',
              address_2: '',
              address_3: '',
              address_keywords: '',
              city: 'Lerum',
              county: '',
              postcode: '443 50',
              country_iso: 'SE',
            },
            parcels: [
              {
                dim_width: this.parcelForm.width,
                dim_height: this.parcelForm.height,
                dim_length: this.parcelForm.length,
                dim_unit: 'cm',
                items: [],
              },
            ],
          },
          format_address_default: true,
          request_id: 123456789,
        };

        this.label = null;

        const itemsQty = this.$refs['itemsToShip'].itemsQty;

        payload.shipment.parcels[0]['items'] = this.items.length
          ? this.items
              .filter(({ item_id }) => itemsQty[item_id] && itemsQty[item_id] > 0)
              .map((item) => ({
                description: 'Test Item One',
                origin_country: 'GB',
                quantity: itemsQty[item.item_id],
                value: item.price_incl_tax * itemsQty[item.item_id],
                value_currency: 'GBP',
                weight: this.itemsWeight[item.item_id],
                weight_unit: 'kg',
                sku: item.sku,
                hs_code: '12345',
              }))
          : [];

        this.createLabelLoading = true;
        const {
          data: { status, data },
        } = await DataService.post('courier/1/label', payload);
        this.createLabelLoading = false;

        if (status === 'success') {
          this.label = data;
          Notification['success']({
            message: 'Created label successfully',
          });
        }
      }
    },
    async createShipment(withLabel = false) {
      this.savingShipment = true;
      const items = Object.entries(this.$refs['itemsToShip'].itemsQty)
        .filter(([key, value]) => {
          return key && value;
        })
        .map(([key, value]) => ({
          order_item_id: key,
          qty: value,
        }));

      let payload = {};
      if (withLabel) {
        const tracks = [
          {
            track_number: this.label.tracking_codes[0],
            title: this.label.tracking_urls[0],
            carrier_code: this.label.courier,
          },
        ];

        payload = {
          entity: {
            items,
            tracks,
            notify: true,
            packages: [],
            comments: [
              {
                is_customer_notified: 0,
                parent_id: this.$store.state.orders.order.entity_id,
                comment: this.labelUrl,
                is_visible_on_front: 0,
              },
            ],
          },
        };
      } else {
        payload = {
          entity: {
            items,
            tracks: this.tracks,
            notify: true,
            appendComment: false,
            packages: [],
          },
        };
      }

      await this.$store.dispatch('createShipment', { payload, orderId: this.$route.params.id });
      this.savingShipment = false;
    },
    viewLabel() {
      window.open(this.labelUrl, '_blank');
    },
    addTrack() {
      this.tracks = [...this.tracks, { carrier_code: '', title: '', track_number: '' }];
    },
  },
  mounted() {
    if (!this.isCreate) {
      this.$store.commit('setShipment', this.$route.params.shipmentId);
    }
  },
};
</script>
