<template>
  <FullHeightSdCardWrapper>
    <sdPageHeader title="Order & Account Information" class="order-info-header" />
    <a-row justify="center" :gutter="25">
      <a-col :xs="24">
        <Suspense>
          <template #default>
            <sdCards :title="`Order # ${order.increment_id}(The order confirmation email is not sent)`">
              <a-row justify="space-between">
                <a-col>
                  <p :style="{ marginTop: '15px' }">Order Date</p>
                </a-col>
                <a-col>
                  <p :style="{ marginTop: '15px' }">
                    <b>{{ dateFormat(order.created_at) }}</b>
                  </p>
                </a-col>
              </a-row>
              <a-row justify="space-between">
                <a-col>
                  <p>Order Status</p>
                </a-col>
                <a-col
                  ><b>{{ order.status }}</b></a-col
                >
              </a-row>
              <a-row justify="space-between">
                <a-col>
                  <p>Purchased From</p>
                </a-col>
                <a-col>
                  <p>
                    <b class="pre-formatted">{{ order.store_name }}</b>
                  </p>
                </a-col>
              </a-row>
              <a-row justify="space-between">
                <a-col>
                  <p>Placed from Ip</p>
                </a-col>
                <a-col>
                  <p>
                    <b>{{ order.remote_ip }}</b>
                  </p>
                </a-col>
              </a-row>
            </sdCards>
          </template>
          <template #fallback>
            <sdCards headless>
              <a-skeleton active />
            </sdCards>
          </template>
        </Suspense>
      </a-col>
      <a-col :md="12" :xs="24" v-show="false">
        <Suspense>
          <template #default>
            <sdCards title="Account Information">
              <a-row justify="space-between">
                <a-col>
                  <p>Customer Name</p>
                </a-col>
                <a-col>
                  <p>
                    <b>{{ order.customer_firstname + ' ' + order.customer_lastname }}</b>
                  </p>
                </a-col>
              </a-row>
              <a-row justify="space-between">
                <a-col>
                  <p>Email</p>
                </a-col>
                <a-col>
                  <p>
                    <b>{{ order.customer_email }}</b>
                  </p>
                </a-col>
              </a-row>
              <a-row justify="space-between">
                <a-col>
                  <p>Customer Group</p>
                </a-col>
                <a-col>
                  <p>
                    <b>{{ customerGroup }}</b>
                  </p>
                </a-col>
              </a-row>
            </sdCards>
          </template>
          <template #fallback>
            <sdCards headless>
              <a-skeleton active />
            </sdCards>
          </template>
        </Suspense>
      </a-col>
    </a-row>
  </FullHeightSdCardWrapper>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { FullHeightSdCardWrapper } from '@/view/styled';
import { dateFormat } from '@/utility/filter';

export default {
  components: {
    FullHeightSdCardWrapper,
  },
  setup() {
    const { state } = useStore();
    const order = computed(() => state.orders.order);
    const customerGroup = order.value.customer_is_guest === 0 ? 'LOGGED IN' : 'NOT LOGGED IN';

    return {
      order,
      customerGroup,
      dateFormat,
    };
  },
};
</script>

<style>
.pre-formatted {
  white-space: pre;
}
.order-info-header {
  padding-top: 0 !important;
}
</style>
