<template>
  <FullHeightSdCardWrapper>
    <sdPageHeader title="Shipping Method" />
    <a-row justify="center" :gutter="25">
      <!-- <a-col :md="12" :xs="24">
        <Suspense>
          <template #default>
            <sdCards v-if="order.payment" title="Payment Information">
              <p>{{ order.payment.additional_information[0] }}</p>
              <p>The order was placed using {{ order.order_currency_code }}</p>
            </sdCards>
          </template>
          <template #fallback>
            <sdCards headless>
              <a-skeleton active />
            </sdCards>
          </template>
        </Suspense>
      </a-col> -->
      <a-col :md="24" :xs="24">
        <Suspense>
          <template #default>
            <sdCards title="Shipping Information">
              <p>
                <b>{{ order.shipping_description }}</b>
              </p>
              <p>Total Shipping Charges: {{ order.order_currency_code }} {{ order.shipping_amount }}</p>
              <!-- <TableWrapper class="table-responsive">
                <a-table
                  :dataSource="tableData"
                  :columns="tableColumns"
                  :loading="isSaving"
                  :pagination="{ defaultPageSize: 5 }"
                />
              </TableWrapper> -->
            </sdCards>
          </template>
          <template #fallback>
            <sdCards headless>
              <a-skeleton active />
            </sdCards>
          </template>
        </Suspense>
      </a-col>
    </a-row>

    <a-modal v-model:visible="visibleNewTrackModal" title="New Track" @ok="onSave" width="40%" centered>
      <AddNewTrackModalWrapper>
        <BasicFormWrapper>
          <a-form name="multi-form" layout="horizontal">
            <a-row :gutter="30">
              <a-col :lg="8" :md="12" :xs="24" class="mb-25">
                <a-form-item label="Carrier">
                  <a-select v-model:value="newTrackForm.carrier" default-value="custom_value">
                    <a-select-option value="custom_value"> Custom Value </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :lg="8" :md="12" :xs="24" class="mb-25">
                <a-form-item label="Title">
                  <a-input v-model:value="newTrackForm.title" />
                </a-form-item>
              </a-col>
              <a-col :lg="8" :md="12" :xs="24" class="mb-25">
                <a-form-item label="Number">
                  <a-input v-model:value="newTrackForm.number" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </BasicFormWrapper>
      </AddNewTrackModalWrapper>
    </a-modal>
  </FullHeightSdCardWrapper>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref, reactive } from 'vue';
import { BasicFormWrapper, FullHeightSdCardWrapper } from '@/view/styled';
import { AddNewTrackModalWrapper } from './style';
import { useRoute } from 'vue-router';
// import { DataService } from '@/config/dataService/dataService';

const tableColumns = [
  {
    title: 'Carrier',
    dataIndex: 'carrier_code',
    key: 'carrier_code',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Number',
    dataIndex: 'track_number',
    key: 'track_number',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];

export default {
  components: {
    BasicFormWrapper,
    // TableWrapper,
    AddNewTrackModalWrapper,
    FullHeightSdCardWrapper,
  },
  setup() {
    const { state, dispatch } = useStore();
    const { params } = useRoute();
    const order = computed(() => state.orders.order);
    const shipment = computed(() => state.orders.shipment);
    const isSaving = computed(() => state.orders.saving);

    const isLoading = ref(false);
    const visibleNewTrackModal = ref(false);

    const newTrackForm = reactive({
      carrier: '',
      title: '',
      number: '',
    });

    const onSave = async () => {
      visibleNewTrackModal.value = false;
      await dispatch('createShipmentTrack', {
        form: newTrackForm,
        orderId: params.id,
      });
    };

    const tableData = computed(() =>
      shipment.value
        ? shipment.value.tracks.map((track) => {
            const { carrier_code, title, track_number, entity_id } = track;

            return {
              key: entity_id,
              carrier_code,
              title,
              track_number,
              action: (
                <div class="table-actions">
                  <>
                    <a-popconfirm title="Sure to delete?" onConfirm={() => dispatch('deleteShipmentTrack', entity_id)}>
                      <sdButton class="btn-icon" type="default" shape="circle">
                        <sdFeatherIcons type="trash" size="16" />
                      </sdButton>
                    </a-popconfirm>
                  </>
                </div>
              ),
            };
          })
        : [],
    );

    return {
      tableColumns,
      tableData,
      isLoading,
      order,
      onSave,
      visibleNewTrackModal,
      newTrackForm,
      isSaving,
    };
  },
};
</script>

<style lang="scss" scoped>
.add-button {
  margin: 30px 0;
}
</style>
