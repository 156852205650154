<template>
  <FullHeightSdCardWrapper>
    <ItemsToShipWrapper>
      <sdPageHeader :title="title" />
      <a-row justify="center" :gutter="25">
        <a-col :xs="24">
          <sdCards>
            <TableWrapper class="table-responsive">
              <a-table :dataSource="tableData" :columns="tableColumns" :pagination="{ defaultPageSize: 5 }" />
            </TableWrapper>
          </sdCards>
        </a-col>
      </a-row>
    </ItemsToShipWrapper>
  </FullHeightSdCardWrapper>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref, onMounted, watch } from 'vue';
import { ItemsToShipWrapper } from './style';
import { FullHeightSdCardWrapper, TableWrapper } from '@/view/styled';
import { useRoute } from 'vue-router';

const tableColumns = [
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
  },
  {
    title: 'Qty',
    dataIndex: 'qty',
    key: 'qty',
  },
  {
    title: 'Qty to ship',
    dataIndex: 'qty_to_ship',
    key: 'qty_to_ship',
  },
];

export default {
  name: 'ItemsToShip',
  components: {
    ItemsToShipWrapper,
    FullHeightSdCardWrapper,
    TableWrapper,
  },
  setup() {
    const { state } = useStore();
    const order = computed(() => state.orders.order);
    const itemsQty = ref({});

    const { params } = useRoute();
    const items = computed(() => {
      if (params.shipmentId === 'create') {
        return state.orders.order.items
          ? state.orders.order.items.filter((item) => item.qty_ordered > item.qty_shipped)
          : [];
      } else {
        const currentShipmentItemsIds = state.orders.shipment
          ? state.orders.shipment.items.map(({ order_item_id, qty }) => ({
              id: order_item_id,
              qty: qty,
            }))
          : [];

        return state.orders.order.items
          ? state.orders.order.items.reduce((result, item) => {
              const shipmenItem = currentShipmentItemsIds.find(({ id }) => id === item.item_id);
              if (shipmenItem) {
                result = [...result, { ...item, real_shipped_qty: shipmenItem.qty }];
              } else {
                result = [...result, { ...item }];
              }

              return result;
            }, [])
          : [];
      }
    });
    const tableData = computed(() =>
      items.value.length
        ? items.value.map((item) => {
            const { item_id, name, sku, qty_ordered, real_shipped_qty } = item;

            return {
              key: item_id,
              product: (
                <div>
                  <div>
                    {name} - {sku}
                  </div>
                  <div>SKU: {sku}</div>
                </div>
              ),
              qty: `Ordered ${qty_ordered}`,
              qty_to_ship:
                params.shipmentId === 'create' ? (
                  <div class="table-last-input-column">
                    <a-input
                      value={itemsQty.value[item_id]}
                      max={qty_ordered}
                      min="0"
                      type="number"
                      onChange={(e) => (itemsQty.value[item_id] = e.target.value)}
                    />
                  </div>
                ) : (
                  <div class="table-last-input-column">{real_shipped_qty}</div>
                ),
            };
          })
        : [],
    );

    const reset = () => {
      items.value.map((item) => {
        itemsQty.value[item.item_id] = null;
      });
    };

    const title = params.shipmentId === 'create' ? 'Items to Ship' : 'Items Shipped';
    tableColumns.forEach((column) => {
      if (column.key === 'qty_to_ship') {
        column.title = params.shipmentId === 'create' ? 'Qty to ship' : 'Qty Shipped';
      }
    });

    onMounted(() => {
      reset();
    });

    watch(order, () => {
      reset();
    });

    return {
      order,
      reset,
      itemsQty,
      params,
      items,
      title,
      tableData,
      tableColumns,
    };
  },
};
</script>
