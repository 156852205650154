import Styled from 'vue3-styled-components';

const InformationPageHeader = Styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 15px;
`;

const ItemsToShipWrapper = Styled.div`

  .header {
    .ant-col {
      font-weight: bold;
    }
  }

  .ant-card-body {
    padding: 30px !important;

    .ant-row {
      margin: 10px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .ant-input {
    padding: 6px 11px;
    width: 200px;
  }
`;

export { InformationPageHeader, ItemsToShipWrapper };
