<template>
  <FullHeightSdCardWrapper>
    <sdPageHeader title="Address Information" />
    <a-row justify="center" :gutter="25">
      <a-col :md="12" :xs="24">
        <Suspense>
          <template #default>
            <sdCards v-if="order.billing_address" title="Billing Address">
              <p>{{ order.billing_address.firstname + ' ' + order.billing_address.lastname }}</p>
              <p>{{ order.billing_address.company }}</p>
              <p>{{ order.billing_address.city }}</p>
              <p>{{ order.billing_address.street ? order.billing_address.street.join(' ') : '' }}</p>
              <p>{{ order.billing_address.region }}</p>
              <p>{{ order.billing_address.country_id }}</p>
              <p>{{ 'T:' + order.billing_address.telephone }}</p>
              <p>{{ order.billing_address?.postcode }}</p>
            </sdCards>
          </template>
          <template #fallback>
            <sdCards headless>
              <a-skeleton active />
            </sdCards>
          </template>
        </Suspense>
      </a-col>
      <a-col :md="12" :xs="24">
        <Suspense>
          <template #default>
            <sdCards title="Shipping Address">
              <p>{{ shippingAddress.firstname + ' ' + shippingAddress.lastname }}</p>
              <p>{{ shippingAddress.city }}</p>
              <p>{{ shippingAddress.street ? shippingAddress.street.join(' ') : '' }}</p>
              <p>{{ shippingAddress.region }}</p>
              <p>{{ shippingAddress.country_id }}</p>
              <p>{{ 'T:' + shippingAddress.telephone }}</p>
              <p>{{ order.shippingAddress?.postcode }}</p>
            </sdCards>
          </template>
          <template #fallback>
            <sdCards headless>
              <a-skeleton active />
            </sdCards>
          </template>
        </Suspense>
      </a-col>
    </a-row>
  </FullHeightSdCardWrapper>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { FullHeightSdCardWrapper } from '@/view/styled';

export default {
  components: {
    FullHeightSdCardWrapper,
  },
  setup() {
    const { state } = useStore();
    const order = computed(() => state.orders.order);
    const shippingAddress = computed(() => {
      const shippingAssignments = state.orders.order?.extension_attributes?.shipping_assignments;

      if (shippingAssignments && shippingAssignments.length) {
        return shippingAssignments[0].shipping.address;
      } else {
        return {};
      }
    });
    console.log(order, 'order-shipping');
    console.log(shippingAddress, 'order-shipping');
    return {
      order,
      shippingAddress,
    };
  },
};
</script>
