import moment from 'moment';

export const fileSize = (size) => {
  if (size > 1024 * 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB';
  } else if (size > 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB';
  } else if (size > 1024 * 1024) {
    return (size / 1024 / 1024).toFixed(2) + ' MB';
  } else if (size > 1024) {
    return (size / 1024).toFixed(2) + ' KB';
  }
  return size.toString() + ' B';
};

export const dateFormat = (date) => {
  const dateFormat = 'MMM D, YYYY LTS';
  const _date = new Date(date);
  return moment(_date).format(dateFormat);
};

export const currencyFromCode = (code) => {
  const allCurrencies = {
    gbp: '£',
  };
  const localizedCode = code ? code.toLowerCase() : 'gbp';
  return allCurrencies[localizedCode];
};
